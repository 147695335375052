import classNames from 'classnames/bind';
import style from './ToolbarButton.module.scss';
import React, { useRef } from 'react';
import SlateColorPicker from './SlateColorPicker';
import EditorIcon from '../../EditorIcon';
import useColorPickerAbsolutePosition from './useColorPickerAbsolutePosition';
import { MARK_FORMAT, SHOW_POP, ShowPopType } from '../../textEditor.constant';
import { Editor } from 'slate';
import { COLORS } from '../../../../constants/colors';
import { useSlate } from 'slate-react';

interface IProps {
  showPop: ShowPopType;
  setShowPop: (showColorPicker: ShowPopType) => void;
  format: 'color' | 'highlight';
  brandColor?: string;
}

const cx = classNames.bind(style);

const ColorPickerButton = ({ showPop, setShowPop, format, brandColor }: IProps) => {
  const editor = useSlate();
  const colorPickerRef = useRef<HTMLButtonElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowPop(showPop === SHOW_POP[format] ? null : SHOW_POP[format]);
  };

  const DEFAULT_COLOR = {
    [MARK_FORMAT.COLOR]: COLORS.black,
    [MARK_FORMAT.HIGHLIGHT]: COLORS.white,
  };
  const getColorFromSlate = () => {
    const mark = Editor.marks(editor);
    return mark?.[format] ?? DEFAULT_COLOR[format];
  };

  return (
    <button className={cx('container')} type={'button'} ref={colorPickerRef} onClick={handleClick}>
      {showPop === SHOW_POP[format] && (
        <SlateColorPicker
          setShowPop={setShowPop}
          format={format}
          initialColor={getColorFromSlate()}
          brandColor={brandColor}
        />
      )}
      <EditorIcon name={format} />
    </button>
  );
};

export default ColorPickerButton;
