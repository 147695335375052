import { startTransition, useEffect, useRef, useState } from 'react';
import { Editor } from 'slate';
import { hexToRgba, rgbaToHex } from '../../../colorPicker/utils/convert';
import { useSlate } from 'slate-react';
import style from './ToolbarButton.module.scss';
import classnames from 'classnames/bind';
import ColorPicker from '../../../colorPicker/ColorPicker';
import { IRgbaColor } from '../../../colorPicker/colorPicker.type';
import useOnClickOutside from '../../../../hook/useOnClickOutside';
import { BRAND_COLOR_TYPE } from '../../../colorPicker/brandColor/brandColor.type';

const cx = classnames.bind(style);

interface IProps {
  format: 'color' | 'highlight';
  initialColor: string;
  setShowPop: (a: null) => void;
  brandColor?: string;
}

const SlateColorPicker = ({ format, initialColor, setShowPop, brandColor }: IProps) => {
  const editor = useSlate();
  const [color, setColor] = useState<string>(initialColor);
  const colorPickerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(colorPickerRef, () => {
    setShowPop(null);
  });

  useEffect(() => {
    Editor.addMark(editor, format, color);
  }, [color]);

  const handleChangeColor = (color: IRgbaColor) => {
    startTransition(() => setColor(rgbaToHex(color)));
  };

  const handleClickBrandColor = () => {
    if (!brandColor) return;
    setColor(brandColor);
  };

  return (
    <div ref={colorPickerRef} className={cx('color-picker-modal')} onClick={(e) => e.stopPropagation()}>
      <ColorPicker
        color={hexToRgba(color)}
        onChange={handleChangeColor}
        {...(brandColor && {
          brandColorOptions: {
            type: BRAND_COLOR_TYPE.BUTTON,
            onClick: handleClickBrandColor,
          },
        })}
      />
    </div>
  );
};

export default SlateColorPicker;
