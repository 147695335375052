import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import ColorPicker from 'ui/common/colorPicker/ColorPicker';
import Portal from 'ui/common/portal/Portal';
import useOnClickOutside from 'ui/hook/useOnClickOutside';
import style from './index.module.scss';
import { IColorPickerCustomPosition, IColorPickerBaseProps, IRgbaColor } from 'ui/common/colorPicker/colorPicker.type';
const cx = classNames.bind(style);

const POPOVER_HEIGHT = 340;

type ColorPickerPopoverProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  customPosition?: IColorPickerCustomPosition;
} & IColorPickerBaseProps<IRgbaColor>;

const ColorPickerPopover = ({
  isVisible,
  setIsVisible,
  color,
  onChange,
  customPosition,
  brandColorOptions,
}: ColorPickerPopoverProps) => {
  // @ts-ignore
  const ref = useRef<HTMLDivElement>(null);
  // @ts-ignore
  const positionRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ top?: number; left?: number }>({});

  useOnClickOutside(ref, () => {
    setIsVisible(false);
  });

  useEffect(() => {
    if (!positionRef.current) return;

    const refTop = positionRef.current?.getBoundingClientRect().top;
    const refLeft = positionRef.current?.getBoundingClientRect().left;

    const rnbColorPickerLeftPosition = refLeft - 652;

    // PopOver이 화면 밖으로 나가지 않도록 위치를 조정한다.
    const isModalOverflowScreen = refTop + POPOVER_HEIGHT > window.innerHeight;
    if (isModalOverflowScreen) {
      setPosition({
        top: window.innerHeight - POPOVER_HEIGHT, // 화면 하단에 맞춘다.
        left: customPosition?.left ? refLeft + customPosition?.left : rnbColorPickerLeftPosition,
      });
    } else {
      setPosition({
        top: customPosition?.top ? refTop + customPosition?.top : refTop,
        left: customPosition?.left ? refLeft + customPosition?.left : rnbColorPickerLeftPosition,
      });
    }
  }, [isVisible, customPosition]);

  if (!isVisible) return null;

  return (
    <>
      {/*@ts-ignore*/}
      <Portal domId={'color-picker-root'}>
        <div
          //@ts-ignore
          ref={ref}
          className={cx('popover-area')}
          style={{
            top: `${position.top}px`,
            left: `${position.left}px`,
          }}
        >
          <ColorPicker color={color} onChange={onChange} brandColorOptions={brandColorOptions} />
        </div>
      </Portal>
      {/*@ts-ignore*/}
      <div ref={positionRef} />
    </>
  );
};

export default ColorPickerPopover;
